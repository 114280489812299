
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import Loader from "@/components/General/Loader.vue";
import DetailHeader from "@/components/Layout/Backoffice/DetailHeader.vue";
import { Notification } from "@/models/notification.interface";
import { Product } from "@/models/product.interface";
import CategoryBreadcrumbs from "@/components/Layout/Common/CategoryBreadcrums.vue";
import { Category } from "@/models/category.interface";
import { CustomDate } from "@/mixins/custom-date";
import { VueEditor } from "vue2-editor";
import ProductDetail from "@/components/Layout/Common/ProductDetail.vue";
import { File } from "@/models/file.interface";
import { ProductDetail as ProductDetailInterface } from "@/models/detail.interface";
import InnerImageZoom from "vue-inner-image-zoom";

@Component({
  components: {
    Loader,
    DetailHeader,
    CategoryBreadcrumbs,
    VueEditor,
    ProductDetail,
    "inner-image-zoom": InnerImageZoom,
  },
})
export default class ProductDetailPage extends mixins(Navigation, CustomDate) {
  loader = false;
  product: Product & { file: File | null; files: File[] } = {
    id: undefined,
    name: "",
    description: "",
    price: 0,
    quantity: 0,
    check_users: false,
    file: null,
    files: [],
    investment: undefined,
    details: [],
    status: {
      name: "",
    },
  };
  categories: { all: Category[]; tree: Category[] } = {
    all: [],
    tree: [],
  };
  activeClass = 0;
  bannerImage: File | null = null;
  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };
  paginationLoader = false;

  private get productImages(): File[] {
    let productImages: File[] = [];
    if (this.product.photo_preview) {
      productImages.push(this.product.photo_preview);
    }
    if (this.product.files && this.product.files.length > 0) {
      productImages.push(...this.product.files);
    }
    return productImages;
  }

  private get productDetails(): ProductDetailInterface[] {
    let productDetails: ProductDetailInterface[] = [];
    if (this.product.details) {
      productDetails = this.product.details.sort((a: any, b: any) => {
        if (a.detail?.type > b.detail?.type) {
          return 1;
        }
        if (a.detail?.type < b.detail?.type) {
          return -1;
        }
        return 0;
      });
    }

    return productDetails;
  }

  currentThumnail(image, index) {
    this.activeClass = index;
    this.bannerImage = image;
  }

  private async created(): Promise<void> {
    this.loader = true;

    await this.loadCategories();

    await this.$store
      .dispatch("products/getProduct", this.$route.params.id)
      .catch(() => {
        const Error: Notification = {
          message: this.$t("Product.fetchError.productById", {
            name: this.$route.params.id,
          }) as string,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });

    this.product = await this.$store.getters["products/getProduct"];
    this.bannerImage = this.product.photo_preview
      ? this.product.photo_preview
      : null;
    await this.getCommentsForProduct(1, this.pagination.itemsPerPage);
    this.setNavigation({
      previousRoute: "/staff/products",
      icon: "mdi-clipboard-list",
      title: this.product.name,
    });
    this.loader = false;
  }

  private async loadCategories() {
    this.categories = await this.$store
      .dispatch("categories/getAssignableCategories")
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Categories.fetchError.get"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async showMoreComments() {
    this.paginationLoader = true;
    await this.getCommentsForProduct(
      this.pagination.page + 1,
      this.pagination.itemsPerPage,
      true
    );
    this.paginationLoader = false;
  }

  private async getCommentsForProduct(
    page: number,
    size: number,
    combine = false
  ) {
    await this.$store
      .dispatch("comments/getProductComments", {
        page: page,
        size: size,
        combine: combine,
        product_id: this.product.id,
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.pd-error1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private get comments() {
    let response = this.$store.getters["comments/getProductComments"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.comments) {
      return response.comments;
    } else {
      return [];
    }
  }

  private get totalPages() {
    const total = this.pagination.totalItems / this.pagination.itemsPerPage;
    if (total > Math.round(total)) {
      return Math.round(total) + 1;
    } else {
      return Math.round(total);
    }
  }
}
